.paymentContainer
  div[class='payment']
  li[class*='adyen-checkout__payment-method'] {
    border: none;
    background-color: var(--colors-color-background-light);
}
.paymentContainer 
  div[class='payment']
  li[class*='adyen-checkout__payment-method']
  div[class*='adyen-checkout__payment-method__header'] {
    padding: var(--spacing-desk-margin-base-top) 0;
}
.paymentContainer
  div[class='payment']
  li[class*='adyen-checkout__payment-method'] 
  div[class*='adyen-checkout__payment-method__details'] {
    padding-left: 0;
    padding-right: 0;
}
.paymentContainer
  div[class='payment']
  li[class*='adyen-checkout__payment-method'] 
  div[class*='adyen-checkout__card__form']
  span[class='adyen-checkout__card__brands'] {
    margin-bottom: 0;
}
.paymentContainer
  div[class='payment']
  div[class*='adyen-checkout__card__form']
  div[class*='adyen-checkout__label']
  span[class*='adyen-checkout__label__text'] {
    margin-top: var(--spacing-desk-global-spacing-spacing-4);
    font-size: var(--extend-fontSize-body-large-regular);
    line-height: var(--extend-lineHeight-heading-desk-medium-bold);
    color: var(--colors-color-text-black);
    font-weight: var(--extend-fontWeight-body-large-bold);
}
.paymentContainer
  div[class='payment']
  div[class*='adyen-checkout__card__form']
  label[class*='adyen-checkout__label']
  span[class*='adyen-checkout__label__text'] {
    margin-top: var(--spacing-desk-global-spacing-spacing-4);
    font-size: var(--extend-fontSize-body-large-regular);
    line-height: var(--extend-lineHeight-heading-desk-medium-bold);
    color: var(--colors-color-text-black);
    font-weight: var(--extend-fontWeight-body-large-bold);
}
.paymentContainer
  div[class='payment'] 
  div[class*='adyen-checkout__loading-input__form']
  div[class*='adyen-checkout__store-details'] 
  span[class='adyen-checkout__checkbox__label'] { 
    font-size: var(--extend-fontSize-body-large-regular);
    line-height: var(--extend-lineHeight-heading-desk-medium-bold);
    font-weight: var(--extend-fontWeight-body-large-bold);
    padding-left: var(--spacing-desk-component-button-full-padding-x);
    padding-top: var(--spacing-desk-global-spacing-spacing-1);
}
.paymentContainer
  div[class*='adyen-checkout__card-input']
  p[class='adyen-checkout-form-instruction'] {
    font-size: var(--extend-fontSize-body-small-regular);
}
.paymentContainer
  div[class*='adyen-checkout__card-input']
  span[class='adyen-checkout__error-text'] {
    font-size: var(--extend-fontSize-body-small-regular);
}
.paymentContainer
  div[class*='adyen-checkout__store-details'] 
  span[class='adyen-checkout__checkbox__label']::before {
    width: calc(var(--spacing-desk-global-spacing-spacing-2) + var(--spacing-desk-global-spacing-spacing-05));
    height: calc(var(--spacing-desk-global-spacing-spacing-4) + var(--spacing-desk-global-spacing-spacing-05));
    top: calc(var(--spacing-desk-global-spacing-spacing-1) - 1px);
    left: calc(var(--spacing-desk-global-spacing-spacing-1) - 1px);
  } 
.paymentContainer
  div[class*='adyen-checkout__store-details'] 
  span[class='adyen-checkout__checkbox__label']::after {
    width: calc(var(--spacing-desk-global-spacing-spacing-5) + var(--spacing-desk-global-spacing-spacing-1));
    height: calc(var(--spacing-desk-global-spacing-spacing-5) + var(--spacing-desk-global-spacing-spacing-1));
    box-shadow: none;
    --tw-border-opacity: 1;
    border: 2px solid rgb(var(--colors-color-border-mid) / var(--tw-border-opacity));
    outline: none;
  } 
.paymentContainer
  div[class*='adyen-checkout__store-details']
  input[class='adyen-checkout__checkbox__input']:checked + span[class='adyen-checkout__checkbox__label']::after {
    --tw-border-opacity: 1;
    border: 1px solid rgb(var(--colors-color-brand-primary-1-base) / var(--tw-bg-opacity));
    background-color: rgb(var(--colors-color-brand-primary-1-base) / var(--tw-bg-opacity));
  }
  
.paymentContainer
  div[class*='adyen-checkout__store-details']
  input[class='adyen-checkout__checkbox__input'] + span[class='adyen-checkout__checkbox__label'] 
  /* Not sure why, but putting class name here doesn't work */
  span {
    display: none;
  }
.paymentContainer
  div[class*='adyen-checkout__store-details']
  input[class='adyen-checkout__checkbox__input']:disabled + span[class='adyen-checkout__checkbox__label'] 
  /* Not sure why, but putting class name here doesn't work */
  span {
    display: inline;
  }
.paymentContainer
  div[class*='adyen-checkout__store-details']
  input[class='adyen-checkout__checkbox__input']:disabled + span[class='adyen-checkout__checkbox__label']::after {
    --tw-border-opacity: 1;
    border: 1px solid rgb(var(--colors-color-brand-primary-1-disabled) / var(--tw-bg-opacity));
    background-color: rgb(var(--colors-color-brand-primary-1-disabled) / var(--tw-bg-opacity));
    box-shadow: none;
    cursor: not-allowed;    
  }
.paymentContainer
  div[class='adyen-checkout__card__form']
  span[class*='adyen-checkout__input'] {
    height: 52px;
    border-radius: 4px;
    border: none;
    --tw-border-opacity: 1;
    box-shadow: 0 0 0 2px rgb(var(--colors-color-border-mid) / var(--tw-border-opacity));
}
.paymentContainer
 div[class='adyen-checkout__card__form']
  span[class*='adyen-checkout__input--error'] {
    --tw-border-opacity: 1;
    box-shadow: 0 0 0 2px rgb(var(--colors-system-red) / var(--tw-border-opacity));
}
.paymentContainer
 div[class='adyen-checkout__card__form']
  span[class*='adyen-checkout__input--valid'] {
    --tw-border-opacity: 1;
    box-shadow: 0 0 0 2px rgb(var(--colors-color-brand-primary-1-base) / var(--tw-bg-opacity));
}
