.wrapperStyles section[id='reward-history_section']::after,
.wrapperStyles section[id='activity-history_section']::after {
  position: absolute;
  bottom: 0;
  height: 25%;
  display: block;
  width: 100%;
  content: '';
  pointer-events: none;
}

.wrapperStyles section[id='reward-history_section'][class*='selected-no-after']::after,
.wrapperStyles section[id='activity-history_section'][class*='selected-no-after']::after {
  display: none;
}

.wrapperStyles section[id='reward-history_section'][class*='selected'],
.wrapperStyles section[id='activity-history_section'][class*='selected'] {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .wrapperStyles section[id='earn-points_section']::after,
  .wrapperStyles section[id='reward-history_section']::after,
  .wrapperStyles section[id='activity-history_section']::after {
    position: absolute;
    bottom: 0;
    height: 25%;
    display: block;
    width: 100%;
    content: '';
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(20%, #fff),
      color-stop(80%, rgba(255, 255, 255, 0))
    );
    background: -webkit-linear-gradient(bottom, #fff 20%, rgba(255, 255, 255, 0) 80%);
    background: linear-gradient(to top, #fff 20%, rgba(255, 255, 255, 0) 80%);
    pointer-events: none;
  }

  .wrapperStyles section[id='earn-points_section'][class*='selected-no-after']::after,
  .wrapperStyles section[id='reward-history_section'][class*='selected-no-after']::after,
  .wrapperStyles section[id='activity-history_section'][class*='selected-no-after']::after {
    display: none;
  }

  .wrapperStyles section[id='earn-points_section'][class*='selected'],
  .wrapperStyles section[id='reward-history_section'][class*='selected'],
  .wrapperStyles section[id='activity-history_section'][class*='selected'] {
    position: relative;
    overflow: hidden;
  }
}
