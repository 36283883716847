@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* PreviewSearchInput */
.sitecore-preview-search-input {
  width: 800px;
  box-sizing: border-box;
  padding: var(--sdc-spacing-xs);
}

.sitecore-preview-search-input:focus {
  outline: 1px solid var(--sdc-palette-grey-400);
}

/* PreviewSearchContent */
.sitecore-preview-search-content {
  /* animation-duration: 500ms; */
  /* animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1); */
  /* will-change: transform, opacity; */
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
  background: var(--sdc-palette-primary-light);
  box-shadow: 2px 5px 5px 5px var(--sdc-palette-grey-400);
  display: flex;
  justify-content: center;
  /* height: 460px; */
  padding-top: 0;
  font-family: var(--sdc-typography-fontFamilySystem);
  z-index: 99 !important;
}

@keyframes slide-up-and-fade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-right-and-fade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-down-and-fade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-left-and-fade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.sitecore-preview-search-content[data-state='open'][data-side='top'] {
  /* animation-name: slide-down-and-fade; */
}

.sitecore-preview-search-content[data-state='open'][data-side='right'] {
  /* animation-name: slide-left-and-fade; */
}

.sitecore-preview-search-content[data-state='open'][data-side='bottom'] {
  /* animation-name: slide-up-and-fade; */
}

.sitecore-preview-search-content[data-state='open'][data-side='left'] {
  /* animation-name: slide-right-and-fade; */
}

/* PreviewSearchSuggestions */
.sitecore-preview-search-suggestions {
  /* display: block;
  box-sizing: border-box;
  list-style: none;
  width: 16rem;
  font-size: var(--sdc-typography-fontSize1-fontSize);
  z-index: 99 !important;
  background-color: white;
  display: flex;
  width: 100%; */
}

/* PreviewSearchSuggestionItem */
.sitecore-preview-search-suggestion-item {
  padding: var(--sdc-spacing-s) var(--sdc-spacing-s);
}

.sitecore-preview-search-suggestion-item:focus,
.sitecore-preview-search-suggestion-item:hover {
  outline: none;
  font-weight: bold;
  color: var(--sdc-palette-primary-main);
  background: #fff;
}

/* PreviewSearchSuggestionTrigger */
.sitecore-preview-search-suggestion-trigger {
  cursor: pointer;
  padding: var(--sdc-spacing-s) var(--sdc-spacing-s);
}

.sitecore-preview-search-suggestion-trigger[data-state='active'],
.sitecore-preview-search-suggestion-trigger:focus,
.sitecore-preview-search-suggestion-trigger:hover {
  outline: none;
  font-weight: bold;
  color: var(--sdc-palette-primary-main);
  background: #fff;
}

/* PreviewSearchSuggestionsGroup */
.sitecore-preview-search-suggestions-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

/* PreviewSearchLink */
.sitecore-preview-search-link {
  color: var(--sdc-palette-primary-main);
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  width: 100%;
}

.sitecore-preview-search-link:focus {
  box-shadow: 2px 2px 4px var(--sdc-palette-primary-main);
}

/* PreviewSearchItems */
.sitecore-preview-search-items {
  flex: 3;
  background: #fff;
  overflow-y: auto;
  display: flex;
}

.sitecore-preview-search-items[data-loading='false'] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  margin: 0;
  padding: var(--sdc-spacing-s);
  gap: var(--sdc-spacing-m);
}

/* PreviewSearchRoot */
.sitecore-preview-search-root {
  /* nirmal to add transition here */
}

/* PreviewSearchItem */
.sitecore-preview-search-item {
}

/* PreviewSearchForm */
.sitecore-preview-search-form {
  position: relative;
}

/* ProductRoot */
.sitecore-product-root {
  box-shadow: 2px 2px 4px var(--sdc-palette-grey-400);
  padding: var(--sdc-spacing-m);
  cursor: pointer;
  display: block;
  border: solid 1px transparent;
  text-align: center;
  height: 180px;
}

.sitecore-product-root:focus-within {
  box-shadow: 2px 2px 4px var(--sdc-palette-primary-main);
}

.sitecore-product-root:hover {
  box-shadow: 2px 2px 4px var(--sdc-palette-primary-main);
}

/* ProductImage */
.sitecore-product-image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

/* ProductImageWrapper */
.sitecore-product-image-wrapper {
  margin: auto auto 10px;
  position: relative;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* ProductName */
.sitecore-product-name {
  max-height: 32px;
  overflow: hidden;
  margin: 0 0 var(--sdc-spacing-m);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: 13px;
  font-weight: var(--sdc-typography-fontSize4-fontWeight);
}

/* ProductContent */
.sitecore-product-content {
  margin: 0;
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  font-weight: var(--sdc-typography-fontWeight);
  line-height: var(--sdc-typography-lineHeight);
  color: var(--sdc-palette-primary-main);
}

/* ProductLink */
.sitecore-product-link {
  text-decoration: none;
  color: var(--sdc-palette-primary-main);
  font-size: var(--sdc-typography-fontSize4-fontSize);
}

.sitecore-product-link:hover {
  text-decoration: none;
}

.sitecore-product-link:focus {
  text-decoration: none;
}

/* Price */
.sitecore-price {
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
}

/* LoaderContainer */
.sitecore-loader-container {
  align-items: center;
  display: flex;
  flex: 1;
}

/* LoaderAnimation */
.sitecore-loader-animation {
  animation: rotate-animation 2s linear infinite;
  display: block;
  fill: var(--sdc-palette-primary-main);
  height: 25px;
  margin: auto;
  width: 25px;
}

/* SearchGroupHeading */
.sitecore-search-group-heading {
  box-sizing: border-box;
  padding-left: var(--sdc-spacing-s);
}
