.myRewardsProgressIndicator:nth-child(1) {
  left: 19%;
}

.myRewardsProgressIndicator:nth-child(2) {
  left: 43%;
}

.myRewardsProgressIndicator:nth-child(3) {
  left: 68%;
}

.myRewardsProgressIndicator:nth-child(4) {
  left: 93%;
  background-color: transparent;
}