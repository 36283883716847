@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* BreadcrumbRoot */
.sitecore-breadcrumb-root {
  width: 100%;
  display: inline-block;
}

/* BreadcrumbList */
.sitecore-breadcrumb-list {
  list-style: none;
  padding: 0;
}

/* BreadcrumbItem */
.sitecore-breadcrumb-item {
  display: inline;
  color: var(--sdc-palette-primary-main);
  margin-left: var(--sdc-spacing-xs);
}

/* BreadcrumbNavigation */
.sitecore-breadcrumb-navigation {
}

/* BreadcrumbLink */
.sitecore-breadcrumb-link {
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  text-decoration: none;
}

/* BreadcrumbSeparator */
.sitecore-breadcrumb-separator {
}

/* SortSelectTrigger */
.sitecore-sort-select-trigger {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: var(--sdc-spacing-xs);
  background-color: transparent;
  height: 40px;
  padding: var(--sdc-spacing-xs) var(--sdc-spacing-m);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  color: var(--sdc-palette-primary-main);
  border: none;
}

.sitecore-sort-select-trigger:focus {
  outline: none;
}

/* GenericSelectTrigger */
.sitecore-generic-select-trigger {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: var(--sdc-spacing-xs);
  background-color: transparent;
  height: 40px;
  padding: var(--sdc-spacing-xs) var(--sdc-spacing-m);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  color: var(--sdc-palette-primary-main);
  border: none;
}

.sitecore-generic-select-trigger:focus {
  outline: none;
}

/* SortSelectContent */
.sitecore-sort-select-content {
  /* background-color: var(--sdc-palette-primary-contrastText); */
  /* background-color: yellowgreen; */
  overflow: hidden;
  /* color: var(--sdc-palette-primary-main); */
  box-shadow: 2px 2px 4px var(--sdc-palette-grey-400);
  position: absolute;
  top: 45px;
  z-index: 100;
  background-color: white;
  width: 100%;
  /* border-radius: 4px; */
  /* border: none; */
  /* border: 1px solid green; */
  /* background-color: black; */
}

.sitecore-sort-select-content:focus-within {
  /* border-color: var(--sdc-palette-primary-dark); */
}

/* GenericSelectContent */
.sitecore-generic-select-content {
  background-color: var(--sdc-palette-primary-contrastText);
  overflow: hidden;
  color: var(--sdc-palette-primary-main);
  box-shadow: 2px 2px 4px var(--sdc-palette-grey-400);
  position: absolute;
  top: 30px;
  z-index: 100;
}

.sitecore-generic-select-content:focus-within {
  border-color: var(--sdc-palette-primary-dark);
}

/* SortSelectViewport */
.sitecore-sort-select-viewport {
  /* margin-top: 12px; */
  margin-top: 0px;
  /* padding: var(--sdc-spacing-xs); */
  z-index: 50000;
}

/* GenericSelectViewport */
.sitecore-generic-select-viewport {
  padding: var(--sdc-spacing-xs);
  z-index: 50000;
}

/* SortSelectOption */
.sitecore-sort-select-option {
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  height: 25px;
  /* padding: 0 var(--sdc-spacing-xs); */
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  color: var(--sdc-palette-primary-main);
  position: relative;
  border-radius: 0;
  min-width: 200px !important;
  margin: 8px 12px;
  outline: none;
  background-color: white;
}

.sitecore-sort-select-option[data-highlighted] {
  border-radius: 0;
  background-color: var(--sdc-palette-primary-dark);
  color: var(--sdc-palette-primary-contrastText);
  /* background-color: brown !important; */
}

.sitecore-sort-select-option[data-disabled] {
  color: var(--sdc-palette-grey-800);
  font-style: italic;
}

/* GenericSelectOption */
.sitecore-generic-select-option {
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  height: 25px;
  padding: 0 var(--sdc-spacing-xs);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  color: var(--sdc-palette-primary-main);
  position: relative;
  border-radius: 0;
}

.sitecore-generic-select-option[data-highlighted] {
  border-radius: 0;
  background-color: var(--sdc-palette-primary-dark);
  color: var(--sdc-palette-primary-contrastText);
}

.sitecore-generic-select-option[data-disabled] {
  color: var(--sdc-palette-grey-800);
  font-style: italic;
}

/* SortSelectValue */
.sitecore-sort-select-value {
  /* color: var(--sdc-palette-primary-main); */
}

/* GenericSelectValue */
.sitecore-generic-select-value {
  color: var(--sdc-palette-primary-main);
}

/* SortSelectIcon */
.sitecore-sort-select-icon {
}

/* GenericSelectIcon */
.sitecore-generic-select-icon {
}

/* SortSelectOptionText */
.sitecore-sort-select-option-text {
}

/* GenericSelectOptionText */
.sitecore-generic-select-option-text {
}

/* ProductRoot */
.sitecore-product-root {
  border: solid 1px var(--sdc-palette-grey-400);
  box-shadow: 2px 2px 4px var(--sdc-palette-grey-400);
  width: 200px;
  padding: var(--sdc-spacing-m);
  /* cursor: pointer; */
  display: block;
}

.sitecore-product-root:focus-within {
  border: solid 1px var(--sdc-palette-primary-main);
}

.sitecore-product-root:hover {
  border: solid 1px var(--sdc-palette-primary-main);
}

/* ProductImage */
.sitecore-product-image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

/* ImageWrapper */
.sitecore-image-wrapper {
  /* margin: auto auto 10px; */
  margin: 10px auto 10px;
  position: relative;
  /* height: 200px; */
  height: 156px !important;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* ProductName */
.sitecore-product-name {
  margin: 0 0 var(--sdc-spacing-m);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize4-fontSize);
  font-weight: var(--sdc-typography-fontSize4-fontWeight);
  line-height: var(--sdc-typography-fontSize4-lineHeight);
}

/* ProductContent */
.sitecore-product-content {
  margin: 0;
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  font-weight: var(--sdc-typography-fontWeight);
  line-height: var(--sdc-typography-lineHeight);
  color: var(--sdc-palette-primary-main);
}

/* ProductSku */
.sitecore-product-sku {
  font-family: var(--sdc-typography-fontFamilySystem);
  color: var(--sdc-palette-primary-main);
  font-size: var(--sdc-typography-fontSize1-fontSize);
}

/* ProductLink */
.sitecore-product-link {
  text-decoration: none;
  color: var(--sdc-palette-primary-main);
  font-size: var(--sdc-typography-fontSize4-fontSize);
}

.sitecore-product-link:hover {
  text-decoration: none;
}

.sitecore-product-link:focus {
  text-decoration: none;
}

/* Price */
.sitecore-price {
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
}

/* AccordionItemCheckbox */
.sitecore-accordion-item-checkbox {
  all: unset;
  background-color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.sitecore-accordion-item-checkbox[data-state='checked'] {
  color: var(--sdc-palette-primary-contrastText);
  background-color: var(--sdc-palette-primary-main);
}

.sitecore-accordion-item-checkbox:focus {
  border: solid 1px var(--sdc-palette-primary-main);
}

/* AccordionItemToggle */
.sitecore-accordion-item-toggle {
  all: unset;
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--sdc-typography-fontSize1-fontSize);
  margin-right: var(--sdc-spacing-s);
}

.sitecore-accordion-item-toggle:focus {
  border: solid 1px var(--sdc-palette-primary-main);
}

.sitecore-accordion-item-toggle[data-state='on'] {
  background-color: var(--sdc-palette-primary-main);
  color: var(--sdc-palette-primary-contrastText);
}

/* AccordionItemCheckboxIndicator */
.sitecore-accordion-item-checkbox-indicator {
  color: var(--sdc-palette-primary-contrastText);
  width: 15px;
  height: 15px;
}

/* AccordionValueList */
.sitecore-accordion-value-list {
  list-style: none;
  width: 100%;
}

.sitecore-accordion-value-list li {
  padding: var(--sdc-spacing-xs) 0;
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
}

/* PSP-2319: For selected facet labels  */
.sitecore-accordion-value-list li[data-state='selected'] {
  font-weight: 700 !important;
}

.sitecore-accordion-value-list[data-orientation='horizontal'] {
  display: flex;
  flex-direction: row;
}

/* AccordionItemCheckboxLabel */
.sitecore-accordion-item-checkbox-label {
  padding-left: var(--sdc-spacing-xs);
  color: var(--sdc-palette-primary-main);
  cursor: pointer;
}

/* AccordionItem */
.sitecore-accordion-item {
  display: flex;
  align-items: center;
}

.sitecore-accordion-item[data-state='selected'] div {
  font-weight: 700 !important;
}

/* AccordionHeader */
.sitecore-accordion-header {
  display: flex;
  margin-top: var(--sdc-spacing-s);
  margin-bottom: var(--sdc-spacing-s);
}

/* AccordionTrigger */
.sitecore-accordion-trigger {
  align-items: center;
  color: var(--sdc-palette-primary-main);
  display: flex;
  font-size: var(--sdc-typography-fontSize2-fontSize);
  height: 45px;
  justify-content: space-between;
  line-height: 1;
  padding: 0 var(--sdc-spacing-m);
  flex: 1 1 0;
  background: none;
  border: none;
  cursor: pointer;
}

/* AccordionFacetsFacet */
.sitecore-accordion-facets-facet {
  border-bottom: solid 1px var(--sdc-palette-grey-800);
  display: flex;
  gap: 22px;
}

/* AccordionFacetsRoot */
.sitecore-accordion-facets-root {
}

/* PaginationRoot */
.sitecore-pagination-root {
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  margin-top: var(--sdc-spacing-m);
}

/* PaginationPrevPage */
.sitecore-pagination-prev-page {
  display: inline;
  cursor: pointer;
  margin: 0 5px;
}

.sitecore-pagination-prev-page[data-current='true'] {
  color: gray;
  pointer-events: none;
  text-decoration-line: none;
  display: none;
}

/* PaginationNextPage */
.sitecore-pagination-next-page {
  display: inline;
  cursor: pointer;
  margin: 0 5px;
}

.sitecore-pagination-next-page[data-current='true'] {
  color: gray;
  pointer-events: none;
  text-decoration-line: none;
  display: none;
}

/* PaginationFirstPage */
.sitecore-pagination-first-page {
  font-size: var(--sdc-typography-fontSize2-fontSize);
  cursor: pointer;
  margin: 0 5px;
}

.sitecore-pagination-first-page[data-current='true'] {
  color: gray;
  pointer-events: none;
  text-decoration-line: none;
  display: none;
}

/* PaginationLastPage */
.sitecore-pagination-last-page {
  font-size: var(--sdc-typography-fontSize2-fontSize);
  cursor: pointer;
  margin: 0 5px;
}

.sitecore-pagination-last-page[data-current='true'] {
  color: gray;
  pointer-events: none;
  text-decoration-line: none;
}

/* PaginationPage */
.sitecore-pagination-page {
  /* cursor: pointer; */
  /* margin: 0 5px; */
}

.sitecore-pagination-page[data-current='true'] {
  color: gray;
  pointer-events: none;
  text-decoration-line: none;
}

/* PaginationPages */
.sitecore-pagination-pages {
  display: inline;
}

/* Wrapper */
.sitecore-wrapper {
}

/* MainArea */
.sitecore-main-area {
  display: flex;
  max-width: 100%;
  position: relative;
}

/* LeftArea */
.sitecore-left-area {
  position: relative;
  flex: 1 1;
  margin-right: var(--sdc-spacing-l);
}

/* RightArea */
.sitecore-right-area {
  display: flex;
  flex-direction: column;
  flex: 4 1;
}

/* RightTopArea */
.sitecore-right-top-area {
  display: flex;
  justify-content: space-between;
}

/* Grid */
/* .sitecore-grid {
  width: 100%;
  display: grid;
  grid-gap: var(--sdc-spacing-m);
  grid-template-columns: repeat(auto-fill, calc(200px + var(--sdc-spacing-m) * 2 + 2px));
  grid-auto-flow: row;
} */

/* ClearFilters */
.sitecore-clear-filters {
  background: none;
  border: none;
  color: var(--sdc-palette-primary-main);
}

.sitecore-clear-filters:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* SelectedFiltersList */
.sitecore-selected-filters-list {
  width: 100%;
  list-style: none;
  padding-left: var(--sdc-spacing-s);
}

/* SelectedFiltersListItem */
.sitecore-selected-filters-list-item {
  /* display: flex; */
  justify-content: space-between;
  padding: var(--sdc-spacing-xs) 0 var(--sdc-spacing-xs) 0;
}

/* SelectedFiltersListItemText */
.sitecore-selected-filters-list-item-text {
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
}

/* SelectedFiltersListItemButton */
.sitecore-selected-filters-list-item-button {
  background: none;
  border: none;
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  cursor: pointer;
  margin-left: 8px;
}
.sitecore-selected-filters-list-item-button:hover {
  text-decoration: underline;
}

/* PageControls */
.sitecore-page-controls {
  display: flex;
  justify-content: space-between;
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
}

/* QuerySummary */
.sitecore-query-summary {
  color: var(--sdc-palette-primary-main);
  font-family: var(--sdc-typography-fontFamilySystem);
  font-size: var(--sdc-typography-fontSize1-fontSize);
  font-weight: bold;
  margin: auto 0;
}

/* LoaderContainer */
.sitecore-loader-container {
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 100;
}

/* LoaderAnimation */
.sitecore-loader-animation {
  animation: rotate-animation 2s linear infinite;
  display: block;
  fill: var(--sdc-palette-primary-main);
  height: 50px;
  margin: auto;
  width: 50px;
}

/* NoResults */
.sitecore-no-results {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}

/* RangeFacetRoot */
.sitecore-range-facet-root {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 20px;
  margin-bottom: var(--sdc-spacing-l);
}

/* RangeFacetTrack */
.sitecore-range-facet-track {
  background-color: var(--sdc-palette-grey-400);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
}

/* RangeFacetRange */
.sitecore-range-facet-range {
  position: absolute;
  background-color: var(--sdc-palette-primary-main);
  border-radius: 9999px;
  height: 100%;
}

/* RangeFacetStart */
.sitecore-range-facet-start {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 10px grey;
  border-radius: 10px;
  font-family: Raleway, 'Helvetica Neue', Verdana, Arial, sans-serif;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.sitecore-range-facet-start:hover {
  background-color: var(--sdc-palette-primary-main);
}

.sitecore-range-facet-start:focus {
  outline: none;
  box-shadow: 0 0 0 3px grey;
}

.sitecore-range-facet-start span {
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 14px;
  font-family: Raleway, 'Helvetica Neue', Verdana, Arial, sans-serif;
}

/* RangeFacetEnd */
.sitecore-range-facet-end {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 10px grey;
  border-radius: 10px;
  font-family: Raleway, 'Helvetica Neue', Verdana, Arial, sans-serif;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.sitecore-range-facet-end:hover {
  background-color: var(--sdc-palette-primary-main);
}

.sitecore-range-facet-end:focus {
  outline: none;
  box-shadow: 0 0 0 3px grey;
}

.sitecore-range-facet-end span {
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 14px;
  font-family: Raleway, 'Helvetica Neue', Verdana, Arial, sans-serif;
}
